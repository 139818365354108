import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AppStateProvider, { useAppState } from '../video/state';
import ErrorDialog from '../video/components/ErrorDialog/ErrorDialog';
import generateConnectionOptions from '../video/utils/generateConnectionOptions/generateConnectionOptions';
import { VideoProvider } from '../video/components/VideoProvider';
import { ChatStateProvider } from '../chat/state/context';
import { getClassesAction } from '../../actions/common';

const userDetailsSelector = state => state.user.details;
const classesSelector = state => state.common.classes;

const WithProviders = ({ children }) => {
  const { error, setError, settings, getToken, setToken, token } = useAppState();
  const connectionOptions = generateConnectionOptions(settings);

  const dispatch = useDispatch();

  const userDetails = useSelector(userDetailsSelector);
  const classes = useSelector(classesSelector);

  const id = userDetails?.id || '';
  const active = userDetails?.active;

  useEffect(() => {
    if (id) {
      getToken(id, 'main');
      if (active) {
        dispatch(getClassesAction());
      }
    }
  }, [id, active]);

  const fetchToken = () => {
    return getToken(id);
  };

  return (
    <Fragment>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ChatStateProvider
        token={token}
        userDetails={userDetails}
        identity={id}
        fetchToken={fetchToken}
        setToken={setToken}
        classes={classes}
      >
        <VideoProvider options={connectionOptions} onError={setError}>
          {children}
        </VideoProvider>
      </ChatStateProvider>
    </Fragment>
  );
};

export default function CommunicationProvider({ children }) {
  return (
    <AppStateProvider>
      <WithProviders>{children}</WithProviders>
    </AppStateProvider>
  );
}
