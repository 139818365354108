import { Provider } from 'react-redux';
import React from 'react';
import withRedux from 'next-redux-wrapper';
import 'react-toastify/scss/main.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { ToastContainer, Zoom } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CookieConsent from 'react-cookie-consent';

import { createApplicationInitAction } from '../common/actions/application';
import { theme } from '../common/styles/theme';
import store from '../common/store';
import { COOKIE_POLICY_ROUTE } from '../common/routes';
import { appWithTranslation, Link } from '../common/helpers/i18n';
import {
  cookieConsentButtonStyles,
  cookieConsentStyles,
} from '../common/styles/cookieConsentStyles';
import '../common/components/payment/CardSectionStyles.css';
import CommunicationProvider from '../common/components/communication/CommunicationProvider';
import '@react-pdf-viewer/core/lib/styles/index.css';

import '../common/styles/main.scss';
import { useEffect } from 'react'
import { useRouter } from 'next/router'

import * as ga from '../common/helpers/ga'

function Lern({ Component, pageProps }) {

  if (process.browser) {
    store.dispatch(createApplicationInitAction());
  }
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
  });

  const router = useRouter();
  const handleRouteChange = (url) => {
    ga.pageview(url)
  }

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    // if (process.env.NODE_ENV === 'production') {
    //   handleRouteChange(router.asPath);
    // }
  }, []);

  if (process.env.NODE_ENV === 'production' && process.browser) {
    useEffect(() => {
      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange)

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }, [router.events])
  }

  // Information that was returned  from 'getInitialProps' are stored in the props i.e. pageProps
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY);
  return (
    <MuiThemeProvider theme={theme}>
      <CommunicationProvider>
        <ToastContainer
          position="top-center"
          newestOnTop
          transition={Zoom}
          draggablePercent={50}
          hideProgressBar
        />

        <Provider store={store}>
          <CssBaseline />

          <Elements stripe={stripePromise}>
            <Component {...pageProps} />
          </Elements>
        </Provider>

        <CookieConsent
          sameSite="lax"
          location="bottom"
          buttonClasses="btn btn-primary"
          style={cookieConsentStyles}
          buttonStyle={cookieConsentButtonStyles}
          contentStyle={{ flex: '1 0 300px' }}
          containerClasses="alert alert-warning col-lg-12"
          contentClasses="text-capitalize"
          cookieName="LERN-Cookie-consent"
        >
          This website uses cookies to enhance the user experience.{' '}
          <span style={{ fontSize: '14px' }}>
              Check our{' '}
            <Link href={COOKIE_POLICY_ROUTE}>
                <a>cookie policy</a>
              </Link>{' '}
            for more details.
            </span>
        </CookieConsent>
      </CommunicationProvider>
    </MuiThemeProvider>
  )
}

// makeStore function that returns a new store for every request
const makeStore = () => store;

Lern.getInitialProps = async ( { Component, ctx} ) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  return { pageProps }
}

// withRedux wrapper that passes the store to the App Component
export default withRedux(makeStore)(appWithTranslation(Lern));


  // componentDidCatch(error, errorInfo) {
  //   if (process.env.NODE_ENV === 'production') {
  //     Sentry.withScope(scope => {
  //       Object.keys(errorInfo).forEach(key => {
  //         scope.setExtra(key, errorInfo[key]);
  //       });
  //
  //       Sentry.captureException(error);
  //     });
  //   }
  //   super.componentDidCatch(error, errorInfo);
  // }

