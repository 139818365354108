
export const cookieConsentStyles =  {
  alignItems: 'baseline',
  flexWrap: 'wrap',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  width: '100%',
  zIndex: 999,
  bottom: 0,
  padding: 20,
  display: 'flex',
  color: '#728196',
  backgroundColor: '#FFF',
  textAlign: 'center',
  fontSize: 14,
  borderTop: '1px solid #728196',
};

export const cookieConsentButtonStyles =  {
  color: "#fff",
  boxShadow: "0 8px 12px 0 rgba(0, 182, 183, 0.24), 0 4px 12px 0 rgba(0, 182, 183, 0.12)",
  borderRadius: 4,
  backgroundColor: '#00b6b7',
  padding: "5px 20px",
};
